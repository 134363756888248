import {motion} from "framer-motion";

const HowExploratoryAnalyticsWorks = () => {
	return (
		<section id="howexploratoryanaltyicsworks" className="contact py-20">

			<div className="md:flex md:justify-center md:gap-16 mt-5">

				<motion.div
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{duration: 0.5}}
					variants={{
						hidden: {opacity: 0, x: -50},
						visible: {opacity: 1, x: 0},
					}}
				>
					<h2 className="text-4xl z-10 md:text-start text-cyan-300 hover:text-red transition duration-1000">HOW
						DOES <span className="text-cyan-300">EXPLORATORY ANALYTICS</span> WORK?</h2>
				</motion.div>
			</div>

			{/* SKILLS */}
			<div className="md:flex md:justify-between mt-30 gap-32">
				{/* EXPERIENCE */}
				<motion.div
					className="md:w-1/3 mt-10"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-32">
						<div className="z-10">
							<p className="font-semibold text-5xl text-red hover:text-cyan-300 transition duration-1000">01</p>
							<h3 className="font-semibold text-3xl mt-3">PREPARE AND UPLOAD
								YOUR DATA</h3>
						</div>
					</div>
					<h4 className="pt-10">Convert your data into the <span
						className="text-cyan-300">Intonate</span> standardized data format.
						Then, upload your data to the <span
							className="text-cyan-300">Intonate</span> platform
						using whatever organization scheme you see
						fit. Organize into working directories by date, subject, type... etc.
						This is the first step in narrowing the scope of
						the process of analyzing your data.</h4>
				</motion.div>

				{/* INNOVATIVE */}
				<motion.div
					className="md:w-1/3 mt-10"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{delay: 0.2, duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-32">
						<div className="z-10">
							<p className="font-semibold text-5xl text-red hover:text-cyan-300 transition duration-1000">02</p>
							<h3 className="font-semibold text-3xl mt-3">HIGH LEVEL
								EXPLORATION</h3>
						</div>
						<div className="w-1/2 md:w-3/4 h-32  absolute right-0 top-0 z-[-1]"/>
					</div>
					<h4 className="pt-10">Begin to explore your data set from the summary dashboard
						by quickly generating word reports against different partitions of your
						data.
						From here you can also generate nested metrics and context reports that
						will give you more information about how these words are used in your
						dataset and the words that appear around them.</h4>
					<h4 className="pt-10">In addition, the insights dashboard will allow you to view
						some insights that were auto generated from your data. These insights can help to
						direct your search.</h4>
				</motion.div>
				{/* IMAGINATIVE */}
				<motion.div
					className="md:w-1/3 mt-10"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{delay: 0.4, duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-32">
						<div className="z-10">
							<p className="font-playfair font-semibold text-5xl text-red hover:text-cyan-300 transition duration-1000">03</p>
							<h3 className="font-playfair font-semibold text-3xl mt-3">IDENTIFY
								CRITICAL AND ACTIONABLE INSIGHTS</h3>
						</div>
						<div className="w-1/2 md:w-3/4 h-32 absolute right-0 top-0 z-[-1]"/>
					</div>
					<h4 className="mt-10">Once you have identified insights that are actionable for
						your business, use our rich xml query language
						to automatically and reliably retrieve similiar insights in all
						partitions of your dataset.</h4>
				</motion.div>

				<motion.div
					className="md:w-1/3 mt-10"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{delay: 0.4, duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-32">
						<div className="z-10">
							<p className="font-playfair font-semibold text-5xl text-red hover:text-cyan-300 transition duration-1000">04</p>
							<h3 className="font-playfair font-semibold text-3xl mt-3">OPTIONAL:
								EXPORT QUERY RESULTS FOR VISUALIZATION</h3>
						</div>
						<div className="w-1/2 md:w-3/4 h-32 absolute right-0 top-0 z-[-1]"/>
					</div>
					<h4 className="mt-10">Once you are satisfied with the results of your queries,
						the <span className="text-cyan-300">Intonate</span> database
						can be connected to any external visualization tool that you choose.
					</h4>
				</motion.div>

			</div>
		</section>
	);
};

export default HowExploratoryAnalyticsWorks;
