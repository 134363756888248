import {motion} from "framer-motion";

const HowExploratoryAnalyticsWorks = () => {
	return (
		<section id="howagilecustomercareworks" className="contact py-20">

			<div className="md:flex md:justify-center md:gap-16 mt-5">

				<motion.div
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{duration: 0.5}}
					variants={{
						hidden: {opacity: 0, x: -50},
						visible: {opacity: 1, x: 0},
					}}
				>
					<h2 className="text-4xl z-10 md:text-start text-cyan-300 hover:text-red transition duration-1000">HOW
						DOES <span className="text-cyan-300">AGILE CUSTOMER CARE</span> WORK?
					</h2>
				</motion.div>
			</div>

			{/* SKILLS */}
			<div className="md:flex md:justify-between mt-32 gap-32">
				{/* EXPERIENCE */}
				<motion.div
					className="md:w-1/3 mt-10"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-32">
						<div className="z-10">
							<p className="font-semibold text-5xl text-red hover:text-cyan-300 transition duration-1000">01</p>
							<h3 className="font-semibold text-3xl mt-3">PREPARE AND UPLOAD
								YOUR DATA</h3>
						</div>
					</div>
					<h4 className="pt-10">This step is very similiar to step 01 of exploratory
						analytics. Adding detailed metadata to your upload for each transcript
						will make the process more effective. Intonate will automatically turn
						your
						metadata into a tool with which you can quickly sift your data.</h4>
				</motion.div>

				{/* INNOVATIVE */}
				<motion.div
					className="md:w-1/3 mt-10"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{delay: 0.2, duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-32">
						<div className="z-10">
							<p className="font-semibold text-5xl text-red hover:text-cyan-300 transition duration-1000">02</p>
							<h3 className="font-semibold text-3xl mt-3">UNDERSTAND YOUR
								CUSTOMER</h3>
						</div>
						<div className="w-1/2 md:w-3/4 h-32  absolute right-0 top-0 z-[-1]"/>
					</div>
					<h4 className="pt-10">Your agents will be able to immediately locate the caller
						in your database using simple intuitive metadata and key word searches.
						They will be able to read and listen to the caller's previous
						conversations and
						better understand their needs.</h4>
				</motion.div>
				{/* IMAGINATIVE */}
				<motion.div
					className="md:w-1/3 mt-10"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{delay: 0.4, duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-32">
						<div className="z-10">
							<p className="font-playfair font-semibold text-5xl text-red hover:text-cyan-300 transition duration-1000">03</p>
							<h3 className="font-playfair font-semibold text-3xl mt-3">LEARN
								FROM EXPERIENCE</h3>
						</div>
						<div className="w-1/2 md:w-3/4 h-32 absolute right-0 top-0 z-[-1]"/>
					</div>
					<h4 className="mt-10">Managers will be able to use Intonate to track the
						lifetime of a customer, identify consistent pain points and understand
						what agents are doing well and what can be improved. </h4>
				</motion.div>

				{/*<motion.div*/}
				{/*	className="md:w-1/3 mt-10"*/}
				{/*	initial="hidden"*/}
				{/*	whileInView="visible"*/}
				{/*	viewport={{once: true, amount: 0.5}}*/}
				{/*	transition={{delay: 0.4, duration: 0.5}}*/}
				{/*	variants={{*/}
				{/*		hidden: {opacity: 0, y: 50},*/}
				{/*		visible: {opacity: 1, y: 0},*/}
				{/*	}}*/}
				{/*>*/}
				{/*	<div className="relative h-32">*/}
				{/*		<div className="z-10">*/}
				{/*			<p className="font-playfair font-semibold text-5xl text-red hover:text-cyan-300 transition duration-1000">04</p>*/}
				{/*			<h3 className="font-playfair font-semibold text-3xl mt-3">OPTIONAL:*/}
				{/*				EXPORT QUERY RESULTS FOR VISUALIZATION</h3>*/}
				{/*		</div>*/}
				{/*		<div className="w-1/2 md:w-3/4 h-32 absolute right-0 top-0 z-[-1]"/>*/}
				{/*	</div>*/}
				{/*	<h4 className="mt-10">Once you are satisfied with the results of your queries,*/}
				{/*		the <span className="text-cyan-300">Intonate</span> database*/}
				{/*		can be connected to any external visualization tool that you choose.*/}
				{/*	</h4>*/}
				{/*</motion.div>*/}

			</div>
		</section>
	);
};

export default HowExploratoryAnalyticsWorks;
