import './SoundWaveLine.scss';

const SoundWaveLine = ({size}) => {

	return (
		<div className='soundwave'>
			{Array(size).fill(0).map((value, index) => <div key={index} className='circle'/>)}
		</div>

	)
}


export default SoundWaveLine;