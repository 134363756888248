import {motion} from "framer-motion";
import SoundWaveLine from "../animations/SoundWaveLine/SoundWaveLine";


const About = () => {

	return (
		<section id="about" className="pt-20 pb-24">
			<div className="md:flex md:justify-between md:gap-16 mt-32">

				<motion.div
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{duration: 0.5}}
					variants={{
						hidden: {opacity: 0, x: -50},
						visible: {opacity: 1, x: 0},
					}}
				>
					<h2 className="text-4xl z-10 md:text-start text-cyan-300 hover:text-red transition duration-1000">WHAT
						IS THE <span className="text-cyan-300">INTONATE</span> PLATFORM?</h2>
				</motion.div>
			</div>

			{/*</motion.div>*/}
			<div className="md:flex md:justify-between gap-32 ">
				{/* EXPERIENCE */}
				<motion.div
					className="md:w-1/3 mt-20"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-16">
						<div className="z-10">
							<h3 className="font-semibold text-3xl mt-3">ADAPTABLE
								TECHNOLOGY</h3>
						</div>
						<div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]"/>
					</div>
					<div className="mt-5 h-0.5  justify-center relative w-100 ">
						<SoundWaveLine size={42}/>
					</div>

					<h4 className="mt-10  z-[-1]">The <span
						className="text-cyan-300">Intonate</span> speech analytics platform
						serves as an end to end data analytics platform;
						While it is capable of sifting any structured textual data, it was
						originally created for ease of organizing and analyzing call center
						audio transcripts.</h4>

				</motion.div>

				{/* INNOVATIVE */}
				<motion.div
					className="md:w-1/3 mt-20"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{delay: 0.2, duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-16">
						<div className="z-10">
							<h3 className="font-semibold text-3xl mt-3">INTUITIVE
								DESIGN</h3>
						</div>
						<div className="w-1/2 md:w-3/4 h-32  absolute right-0 top-0 z-[-1]"/>
					</div>
					<div className="mt-5 h-0.5  justify-center relative w-100 ">
						<SoundWaveLine size={42}/>
					</div>
					<h4 className="mt-10 "><span className="text-cyan-300">Intonate</span> is not
						just the technology required to discover actionable insights.
						It provides you an intuitive path through the challenging and often
						intimidating task of discovering what insights can be found within your
						data.</h4>
				</motion.div>

				{/*/!* IMAGINATIVE *!/*/}
				<motion.div
					className="md:w-1/3 mt-20"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{delay: 0.4, duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-16">
						<div className="z-10">
							<h3 className="font-playfair font-semibold text-3xl mt-3">CUSTOM
								INTEGRATION</h3>
						</div>
						<div className="w-1/2 md:w-3/4 h-32 absolute right-0 top-0 z-[-1]"/>
					</div>
					<div className="mt-5 h-0.5  justify-center relative w-100 ">
						<SoundWaveLine size={42}/>
					</div>
					<h4 className="mt-10">We integrate with you; not the other way around.
						We will design a custom integration strategy to automate the movement of
						your data to <span className="text-cyan-300">Intonate</span>. In
						Addition, if you
						are starting completely from scratch with speech analytics, we can also
						integrate an ASR (Automatic Speech Recognition) engine of your choice
						into the pipeline</h4>
				</motion.div>
			</div>
		</section>
	)
}
export default About;