import {useState} from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import useMediaQuery from "../hooks/useMediaQuery";
import {Link} from "react-router-dom";

const ScrollLink = ({page, selectedPage, setSelectedPage}) => {
	const lowerCasePage = page.toLowerCase().replaceAll(' ', '');
	// console.log(lowerCasePage);
	return (
		<AnchorLink
			className='hover:text-cyan-300 transition duration-1000'
			href={`#${lowerCasePage}`}
			onClick={() => {
				setSelectedPage(lowerCasePage);
				sessionStorage.setItem('selectedPage', lowerCasePage);
			}}
		>
			{page}
		</AnchorLink>
	);
};

const PageLink = ({page, setSelectedPage}) => {
	const lowerCasePage = page ? page.toLowerCase().replaceAll(' ', '') : '';
	return (
		<Link
			to={`/${lowerCasePage}`}
			onClick={() => {
				setSelectedPage(page ? lowerCasePage : "Home");
				sessionStorage.setItem('selectedPage', lowerCasePage);
			}}
			className='hover:text-cyan-300 transition duration-1000'
		>
			{page ? page : "Home"}
		</Link>
	)
}

const Navbar = ({isTopOfPage, selectedPage, setSelectedPage}) => {
	const [isMenuToggled, setIsMenuToggled] = useState(false);
	const isDesktop = useMediaQuery("(min-width: 768px)");
	const navbarBackground = isTopOfPage ? "" : "bg-not-as-deep-blue";
	return (
		<nav className={`${navbarBackground} z-40 w-full fixed top-0 py-6 opacity-90`}>
			<div className="flex items-center justify-between mx-auto w-5/6 ">
				<h4 className="font-montserrat text-3xl hover:text-cyan-300 transition duration-1000"
				>
					INTONATE
				</h4>

				{/* DESKTOP NAV */}
				{isDesktop ? (sessionStorage.getItem('selectedPage') !== 'documentation' ? (
						<div className="flex justify-between gap-16 font-">
							<ScrollLink
								page="Home"
								selectedPage={selectedPage}
								setSelectedPage={setSelectedPage}
							/>
							<ScrollLink
								page="About"
								selectedPage={selectedPage}
								setSelectedPage={setSelectedPage}
							/>
							<ScrollLink
								page="Use Cases"
								selectedPage={selectedPage}
								setSelectedPage={setSelectedPage}
							/>
							{/*<ScrollLink*/}
							{/*	page="How Exploratory Analytics Works"*/}
							{/*	selectedPage={selectedPage}*/}
							{/*	setSelectedPage={setSelectedPage}*/}
							{/*/>*/}
							<ScrollLink
								page="Contact"
								selectedPage={selectedPage}
								setSelectedPage={setSelectedPage}
							/>
							{/*<PageLink*/}
							{/*	page="Documentation"*/}
							{/*	setSelectedPage={setSelectedPage}*/}
							{/*/>*/}

						</div>
					) : (
						<PageLink
							setSelectedPage={setSelectedPage}
						/>
					)
				) : (
					<button
						className="rounded-full bg-cyan-300 p-2"
						onClick={() => setIsMenuToggled(!isMenuToggled)}
					>
					</button>
				)}

			</div>
		</nav>
	);
};

export default Navbar;
