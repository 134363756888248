import AnchorLink from "react-anchor-link-smooth-scroll";

const DotGroup = ({selectedPage, setSelectedPage}) => {
	const selectedStyles = `relative bg-cyan-300 before:absolute before:w-6 before:h-6 before:rounded-full
    before:border-2 before:border-cyan-300 before:left-[-50%] before:top-[-50%]`;
	return (
		<div className="flex flex-col gap-6 fixed top-[60%] right-7">
			<AnchorLink
				href="#home"
				className={`${
					selectedPage === "home" ? selectedStyles : "bg-dark-grey hover:bg-cyan-300 transition duration-500"
				} w-3 h-3 rounded-full`}
				onClick={() => setSelectedPage("home")}
			/>

			<AnchorLink
				href="#about"
				className={`${
					selectedPage === "about" ? selectedStyles : "bg-dark-grey hover:bg-cyan-300 transition duration-500"
				} w-3 h-3 rounded-full`}
				onClick={() => setSelectedPage("about")}
			/>
			<AnchorLink
				href="#usecases"
				className={`${
					selectedPage === "usecases" ? selectedStyles : "bg-dark-grey hover:bg-cyan-300 transition duration-500"
				} w-3 h-3 rounded-full`}
				onClick={() => setSelectedPage("usecases")}
			/>

			<AnchorLink
				href="#howitworks"
				className={`${
					selectedPage === "howitworks" ? selectedStyles : "bg-dark-grey hover:bg-cyan-300 transition duration-500"
				} w-3 h-3 rounded-full`}
				onClick={() => setSelectedPage("howitworks")}
			/>

			<AnchorLink
				href="#contact"
				className={`${
					selectedPage === "contact" ? selectedStyles : "bg-dark-grey hover:bg-cyan-300 transition duration-500"
				} w-3 h-3 rounded-full`}
				onClick={() => setSelectedPage("contact")}
			/>
		</div>
	);
};

export default DotGroup;
