const Footer = () => {
	return (
		<footer className="h-48 bg-not-as-deep-blue pt-10 bottom-0">
			<div className="w-10/12 mx-auto">
				<div className="md:flex justify-center md:justify-between text-center ">
					<p className="font-playfair font-semibold text-2xl hover:text-cyan-300 transition duration-1000">
						INTONATE
					</p>
					<p className="font-playfair text-md">
						©2023 INTONATE SOLUTIONS. All Rights Reserved.
					</p>
				</div>
			</div>
		</footer>
	)
}

export default Footer;