import {motion} from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";

const ScrollLink = ({page, selectedPage, setSelectedPage}) => {
	const lowerCasePage = page.toLowerCase().replaceAll(' ', '');
	// console.log(lowerCasePage);
	return (
		<AnchorLink
			className='font-playfair font-semibold text-3xl mt-3 text-red'
			href={`#${lowerCasePage}`}
			onClick={() => {
				setSelectedPage(lowerCasePage);
				sessionStorage.setItem('selectedPage', lowerCasePage);
			}}
		>
			{page}
		</AnchorLink>
	);
};

const UseCases = () => {

	return (
		<section id="usecases" className="pt-20 pb-24">
			<div className="md:flex md:justify-between md:gap-16 mt-32">

				<motion.div
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{duration: 0.5}}
					variants={{
						hidden: {opacity: 0, x: -50},
						visible: {opacity: 1, x: 0},
					}}
				>
					<h2 className="text-4xl z-10 md:text-start text-cyan-300 hover:text-red transition duration-1000">SAMPLE
						USE CASES</h2>
				</motion.div>
			</div>

			{/* SKILLS */}
			<div className="md:flex md:justify-between mt-30 gap-32">
				<motion.div
					className="md:w-1/3 mt-10"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{delay: 0.4, duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-32">
						<div className="z-10">
							<h3 className="font-playfair font-semibold text-3xl mt-3 text-red">DATA
								WAREHOUSE</h3>
						</div>
						<div className="w-1/2 md:w-3/4 h-32 absolute right-0 top-0 z-[-1]"/>
					</div>
					<h4 className="mt-5">Intonate allows you to categorize and organize your data
						according to your own preferences and needs.</h4>
				</motion.div>

				{/*/!* EXPERIENCE *!/*/}
				{/*<motion.div*/}
				{/*	className="md:w-1/3 mt-10"*/}
				{/*	initial="hidden"*/}
				{/*	whileInView="visible"*/}
				{/*	viewport={{once: true, amount: 0.5}}*/}
				{/*	transition={{duration: 0.5}}*/}
				{/*	variants={{*/}
				{/*		hidden: {opacity: 0, y: 50},*/}
				{/*		visible: {opacity: 1, y: 0},*/}
				{/*	}}*/}
				{/*>*/}
				{/*	<div className="relative h-32">*/}
				{/*		<div className="z-10">*/}
				{/*			<h3 className="font-semibold text-3xl mt-3 text-red">CUSTOMER*/}
				{/*				EXPERIENCE IMPROVEMENT INITIATIVE</h3>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*	<h4 className="pt-20">*/}
				{/*		This is the traditional speech analytics solution.*/}
				{/*		This solution will include an agent scorecard that you can use to*/}
				{/*		evaluate if your agents are*/}
				{/*		adhering to your call center script to your satisfaction.*/}
				{/*	</h4>*/}
				{/*</motion.div>*/}

				{/* INNOVATIVE */}
				<motion.div
					className="md:w-1/3 mt-10"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{delay: 0.2, duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-32">
						<div className="z-5">
							<AnchorLink
								href={`#howexploratoryanaltyicsworks`}
							>
								<h3 className="font-semibold text-3xl mt-3 text-red hover:text-cyan-300 transition duration-1000">
									EXPLORATORY ANALYTICS</h3>
							</AnchorLink>

						</div>
						<div className="w-1/2 md:w-3/4 h-32  absolute right-0 top-0 z-[-1]"/>
					</div>
					<h4 className="pt-4"> Become more customer driven.
						Use the insights you gain in future product development, customer care philosophy and employee training.
						These insights include but are not limited to:</h4>
					<h4>*Fraud.</h4>
					<h4>*Recurring product issues.</h4>
					<h4>*Legal threats/protection.</h4>
					<h4>*Addressing customer feedback and minimizing churn.</h4>
				</motion.div>
				{/* IMAGINATIVE */}

				<motion.div
					className="md:w-1/3 mt-10"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{delay: 0.4, duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-32">
						<div className="z-10">
							<AnchorLink
								href={`#howagilecustomercareworks`}
							>
								<h3 className="font-playfair font-semibold text-3xl mt-3 text-red hover:text-cyan-300 transition duration-1000">AGILE
									CUSTOMER CARE</h3>
							</AnchorLink>

						</div>
						<div className="w-1/2 md:w-3/4 h-32 absolute right-0 top-0 z-[-1]"/>
					</div>
					<h4 className="mt-3"> Simple queries can allow call center managers and agents to quickly respond to customer care events.</h4>
				</motion.div>

				<motion.div
					className="md:w-1/3 mt-10"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{delay: 0.4, duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
				>
					<div className="relative h-32">
						<div className="z-10">
							<h3 className="font-playfair font-semibold text-3xl mt-3 text-red">ALTERNATIVE
								TEXTUAL ANALYTICS</h3>

						</div>
						<div className="w-1/2 md:w-3/4 h-32 absolute right-0 top-0 z-[-1]"/>
					</div>
					<h4 className="mt-3">This solution is for textual data that did not
						originate from speech data. Intonate can
						be configured to sift any text data.
						This data can include but is not limited to:</h4>
					<h4>*Legal documents</h4>
					<h4>*Messages</h4>
					<h4>*Academic papers</h4>
					<h4>*Medical documents</h4>
				</motion.div>

			</div>
		</section>
	)
}

export default UseCases;