import useMediaQuery from "../hooks/useMediaQuery";
import DotGroup from "./DotGroup";
import Landing from "./Landing";
import About from "./About";
import UseCases from "./UseCases";
import HowExploratoryAnalyticsWorks from "./HowExploratoryAnalyticsWorks";
import Contact from "./Contact";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import HowAgileCustomerCareWorks from "./HowAgileCustomerCareWorks";
import HowAlternativeTextualAnalyticsWorks from "./HowAlternativeTextualAnalyticsWorks";

function Main({selectedPage, setSelectedPage}) {
	const [isTopOfPage, setIsTopOfPage] = useState(true);
	const isDesktop = useMediaQuery("(min-width: 1060px)");

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY === 0) {
				setIsTopOfPage(true);
				setSelectedPage("home");
			}
			if (window.scrollY !== 0) setIsTopOfPage(false);
		};
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [setSelectedPage]);

	return (
		<div className="app">
			<div className="w-5/6 mx-auto md:h-full">
				{isDesktop && (
					<DotGroup
						selectedPage={selectedPage}
						setSelectedPage={setSelectedPage}
					/>
				)}
					<Landing setSelectedPage={setSelectedPage}/>
			</div>
			<div className="w-5/6 mx-auto md:h-full">
				<About/>
			</div>
			<div className="w-5/6 mx-auto md:h-full">
				<UseCases/>
			</div>
			<div className="w-5/6 mx-auto md:h-full">
				<HowExploratoryAnalyticsWorks/>
			</div>
			<div className="w-5/6 mx-auto md:h-full">
				<HowAgileCustomerCareWorks/>
			</div>
			{/*<div className="w-5/6 mx-auto md:h-full">*/}
			{/*	<HowAlternativeTextualAnalyticsWorks/>*/}
			{/*</div>*/}
			<div className="w-5/6 mx-auto md:h-full">
				<Contact/>
			</div>
			<Footer/>
		</div>
	);
}

export default Main;