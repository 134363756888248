import {useForm} from "react-hook-form";
import {motion} from "framer-motion";

const Contact = () => {
	const {
		register,
		trigger,
		formState: {errors},
	} = useForm();

	const onSubmit = async (e) => {
		console.log("~ e", e);
		const isValid = await trigger();
		if (!isValid) {
			e.preventDefault();
		}
	};

	return (
		<section id="contact" className="contact py-48 justify-between">
			<div className="mt-16 md:mt-0 justify-between">

				{/* HEADINGS */}
				<motion.div
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{duration: 0.5}}
					variants={{
						hidden: {opacity: 0, x: 50},
						visible: {opacity: 1, x: 0},
					}}
					className=""
				>
					<h2 className="text-4xl z-10 md:text-start text-cyan-300 hover:text-red transition duration-1000">CONTACT
						US</h2>

				</motion.div>
			</div>

			{/* FORM & IMAGE */}
			<div className="md:flex  gap-16 mt-5">
				<motion.div
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{duration: 0.5}}
					variants={{
						hidden: {opacity: 0, x: 50},
						visible: {opacity: 1, x: 0},
					}}
					className=""
				>
					<div className="">
						<h3 className=" pt-20 w-3/5">Contact us to learn more about what <span
							className="text-cyan-300">Intonate</span> can do for you!</h3>

						<h3 className=" pt-10 w-3/5">We offer demos of the <span
							className="text-cyan-300">Intonate</span> platform. These demos
							usually incorporate sample data but,
							they can also make use of your own data if you would like a more
							personalized experience.</h3>

						<h3 className=" pt-10 w-3/5">You can also schedule meetings to discuss
							Proof Of Concept
							engagements and/or pricing to ensure that <span
								className="text-cyan-300">Intonate</span> is right for
							you.</h3>
					</div>
				</motion.div>

				<motion.div
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{delay: 0.2, duration: 0.5}}
					variants={{
						hidden: {opacity: 0, y: 50},
						visible: {opacity: 1, y: 0},
					}}
					className="basis-1/2 mt-10 md:mt-0"
				>
					<form
						target="_blank"
						onSubmit={onSubmit}
						action="https://formsubmit.co/eef73abf6df89a8a0c016be92f13b980"
						method="POST"
					>
						<input
							className="w-full bg-not-as-deep-blue text-white font-semibold placeholder-white border-2 border-cyan-300 p-3 hover:border-red transition duration-500"
							type="text"
							placeholder="NAME"
							{...register("name", {
								required: true,
								maxLength: 100,
							})}
						/>
						{errors.name && (
							<p className="text-red mt-1">
								{errors.name.type === "required" && "This field is required."}
								{errors.name.type === "maxLength" && "Max length is 100 char."}
							</p>
						)}

						<input
							className="w-full bg-not-as-deep-blue text-white font-semibold placeholder-white border-2 border-cyan-300 p-3 mt-5 hover:border-red  transition duration-500"
							type="text"
							placeholder="EMAIL"
							{...register("email", {
								required: true,
								pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
							})}
						/>
						{errors.email && (
							<p className="text-red mt-1">
								{errors.email.type === "required" && "This field is required."}
								{errors.email.type === "pattern" && "Invalid email address."}
							</p>
						)}

						<textarea
							className="w-full bg-not-as-deep-blue text-white font-semibold placeholder-white border-2 border-cyan-300 p-3 mt-5 hover:border-red  transition duration-500"
							name="message"
							placeholder="MESSAGE"
							rows="4"
							cols="50"
							{...register("message", {
								required: true,
								maxLength: 2000,
							})}
						/>
						{errors.message && (
							<p className="text-red mt-1">
								{errors.message.type === "required" &&
									"This field is required."}
								{errors.message.type === "maxLength" &&
									"Max length is 2000 char."}
							</p>
						)}

						<button
							className="p-5 bg-not-as-deep-blue font-semibold border-2 border-cyan-300 text-cyan-300 mt-5 hover:border-red hover:text-white transition duration-500  transition duration-500"
							type="submit"
						>
							SEND US A MESSAGE
						</button>
					</form>
				</motion.div>
			</div>
		</section>
	);
};

export default Contact;
