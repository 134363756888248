import {motion} from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";
import SoundWaveLine from "../animations/SoundWaveLine/SoundWaveLine";


const Landing = ({setSelectedPage}) => {

	return (
		<section
			id="home"
			className="md:flex md:justify-between md:items-center gap-16 md:h-screen py-40"
		>
			<title></title>

			{/* MAIN TEXT */}
			<div className="m-auto">
				{/* HEADINGS */}
				<motion.div
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{duration: 0.5}}
					variants={{
						hidden: {opacity: 0, x: -50},
						visible: {opacity: 1, x: 0},
					}}
				>
					<h2 className="font-montserrat text-6xl z-10 text-center  text-cyan-300 hover:text-red transition duration-1000">INTONATE</h2>
					<div className="justify-center relative mt-3 ml-6">
						<SoundWaveLine size={65}/>
					</div>
					<h2 className="mt-10 mb-7 text-lg text-center ">Welcome to your modular speech
						analytics platform.</h2>
					<h1 className="mt-10 mb-7 text-lg text-center ">Speech analytics technology,
						integration and consulting services to fit your business.</h1>
					<h2 className="mt-10 mb-7 text-lg text-center ">Discover what your customers are saying.</h2>
				</motion.div>
				{/* CALL TO ACTIONS */}
				<motion.div
					className="flex mt-5 justify-center"
					initial="hidden"
					whileInView="visible"
					viewport={{once: true, amount: 0.5}}
					transition={{delay: 0.2, duration: 0.5}}
					variants={{
						hidden: {opacity: 0, x: -50},
						visible: {opacity: 1, x: 0},
					}}
				>
					<AnchorLink
						className="rounded-sm bg-gradient-raincyan py-0.5 px-0.5"
						onClick={() => setSelectedPage("contact")}
						href="#contact"
					>
						<div className="bg-not-as-deep-blue hover:text-red transition duration-500 w-full h-full flex items-center justify-center px-10 py-3 font-playfair">
							CONTACT US
						</div>
					</AnchorLink>
				</motion.div>
			</div>
		</section>)
}

export default Landing;