import Navbar from "./scenes/Navbar";
import {useState} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Main from "./scenes/Main";
import Documentation from "./scenes/Documentation";

function App() {
    const [selectedPage, setSelectedPage] = useState("home");
    const [isTopOfPage, setIsTopOfPage] = useState(true);

  return (
    <div className="app">
        <Router>
            <Navbar
                isTopOfPage={isTopOfPage}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
            />
            <Routes>
                <Route path="/" element={<Main selectedPage={selectedPage} setSelectedPage={setSelectedPage}/>}/>
                {/*<Route path="documentation" element={<Documentation/>}/>*/}
            </Routes>
        </Router>
    </div>
  );
}

export default App;
